<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('graduate_card_create')"
				        :isNewButton="false"
				        :isColumns="true"
				        @filter-div-status="datatable.filterStatus=$event"
				>
					<template v-slot:columns>
						<div class="mb-1" v-for="(column,key) in datatable.columns">
							<b-form-checkbox
								:id="'checkbox-'+key"
								:name="'checkbox-'+key"
								:value="false"
								:unchecked-value="true"
								v-model="column.hidden"
								v-if="column.field!='buttons'"
							>
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('graduate_card_create')"
				              :isNewButton="false"
				              :isColumns="true"
				              @filter-div-status="datatable.filterStatus=$event"
				>
					<template v-slot:columns>
						<div class="mb-1" v-for="(column,key) in datatable.columns">
							<b-form-checkbox
								:id="'checkbox-'+key"
								:name="'checkbox-'+key"
								:value="false"
								:unchecked-value="true"
								v-model="column.hidden"
								v-if="column.field!='buttons'"
							>
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input
                                v-mask="'#######'"
                                v-model="datatable.queryParams.filter.student_number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('national_number')">
                            <b-form-input v-model="datatable.queryParams.filter.national_number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :is_faculty_code_required="true"
                                v-model="datatable.queryParams.filter.program_code" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.registration_academic_year" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('registration_season')">
                            <semester-type-selectbox
                                v-model="datatable.queryParams.filter.registration_season" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('card_status')">
                            <multi-selectbox
                                :multiple="false"
                                :options="cardStatusOptions"
                                v-model="datatable.queryParams.filter.card_status" />
                        </b-form-group>
                    </b-col>
                </b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading"
			           :columns="datatable.columns"
			           :rows="datatable.rows"
			           :total="datatable.total"
			           :queryParams="datatable.queryParams"
			           @on-page-change="onPageChange"
			           @on-sort-change="onSortChange"
			           @on-per-page-change="onPerPageChange"
			           v-show="datatable.showTable"
			>
			</datatable>
            <CommonModal ref="printModal" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ $t('print').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <card-print :cardData="selectedData"></card-print>
                </template>
            </CommonModal>

		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout"
	import Header from "@/layouts/AppLayout/Header"
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

	// Components
	import DatatableFilter from "@/components/datatable/DatatableFilter"
	import Datatable from "@/components/datatable/Datatable"
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
    import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox"
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
    import CommonModal from "@/components/elements/CommonModal";

    //Pages
    import CardPrint from "./CardPrint";
	// Services
	import GraduateApplicationService from "@/services/GraduateApplicationService";

	// Others
	import {ValidationProvider, ValidationObserver} from "vee-validate"
	import qs from 'qs'

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,
			DatatableFilter,
			Datatable,
			ValidationProvider,
			ValidationObserver,
            FacultySelectbox,
            ProgramSelectbox,
            ParameterSelectbox,
            AcademicYearsSelectbox,
            SemesterTypeSelectbox,
            MultiSelectbox,
            CommonModal,
            CardPrint
		},
		metaInfo() {
			return {
				title: this.$t('graduate_card_create')
			}
		},
		data() {
			return {
				datatable: {
					isLoading: false,
					columns: [
						{
							label: '',
							field: 'buttons',
							html: true,
							sortable: false,
							tdClass: 'p-0 text-center w-40 align-middle',
							buttons: [
								{
									text: this.$t('create'),
									class: 'ri-chat-new-line align-middle top-minus-1 mr-3 text-muted',
                                    //permission: "graduateapplication_show",
									callback: (row) => {
                                        this.cardCreate(row)
									}
								},
                                {
                                    text: this.$t('print'),
                                    class: 'ri-printer-line align-middle top-minus-1 mr-3 text-muted',
                                    //permission: "graduateapplicationmeeting_show",
                                    callback: (row) => {
                                        this.cardPrint(row)
                                    }
                                }
							]
						},
						{
							label: this.toUpperCase('id'),
							field: 'id',
							hidden: true,
						},
                        {
                            label: this.toUpperCase('student_number'),
                            field: 'student_number',
                            hidden: false,
                            sortable: false
                        },
						{
							label: this.toUpperCase('name'),
							field: 'name',
							hidden: false,
                            sortable: false
						},
						{
							label: this.toUpperCase('surname'),
							field: 'surname',
							hidden: false,
                            sortable: false
						},
                        {
                            label: this.toUpperCase('faculty'),
                            field: 'faculty_code',
                            hidden: false,
                            sortable: false
                        },
						{
							label: this.toUpperCase('program'),
							field: 'program_code',
							hidden: false,
							sortable: false
						},
						{
							label: this.toUpperCase('national_number'),
							field: 'national_number',
							hidden: false,
							sortable: false
						},
                        {
                            label: this.toUpperCase('card_status')+'/'+this.toUpperCase('issue_date'),
                            field: 'card_status',
                            hidden: false,
                            html: true,
                            formatFn: (row, itm) => {
                                let result = this.$t('card_created') == row ? '<span class="badge badge-pill badge-primary">'+row+'</span>' :
                                    '<span class="badge badge-pill badge-dark">'+row+'</span>'
                                if(itm.issue_date != null) {
                                    result += '<span class="badge badge-pill badge-secondary">'+itm.issue_date+'</span>'
                                }
                                return result
                            }
                        }
					],
					rows: [],
					total: 0,
					filterStatus: true,
					showTable: false,
					queryParams: {
						filter: {},
						sort: 'id',
						page: 1,
						limit: 20
					}
				},

                cardStatusOptions: [
                    {
                        text: this.$t('card_created'),
                        value: 'card_created'
                    },
                    {
                        text: this.$t('card_not_created'),
                        value: 'card_not_created'
                    }
                ],

                selectedData: {}

			}
		},
		created() {
			this.filterSet();
			this.formClear();
            this.checkUrlForStudentNumber()
		},
		methods: {
			filterSet() {
				this.datatable.queryParams.filter = {

				}
			},
			filterClear() {
				this.filterSet()
				this.getRows();
			},
			filter() {
				this.getRows();
			},
			onPageChange(params) {
				this.datatable.queryParams.page = params.currentPage;
				this.getRows();
			},
			onPerPageChange(params) {
				this.datatable.queryParams.limit = params.currentPerPage;
				this.datatable.queryParams.page = 1;
				this.getRows();
			},
			changeColumn(key) {
				this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
			},
			onSortChange(params) {
				const sortType = params[0].type == 'desc' ? '-' : '';
				this.datatable.queryParams.sort = sortType + params[0].field
				this.getRows();
			},
			getRows() {
				this.datatable.showTable = true
				this.datatable.isLoading = true

                this.datatable.rows = [
                    {
                        student_number: 2254044,
                        name: "Feridun",
                        surname: "Hatipoğlu",
                        faculty_code: "Fen-Edebiyat Fakültesi",
                        program_code: "Sosyoloji",
                        national_number: 11111111111,
                        card_status: "Kart Oluşturuldu",
                        issue_date: "21.11.2023"
                    },
                    {
                        student_number: 2254049,
                        name: "Metin",
                        surname: "Yılmaz",
                        faculty_code: "İletişim Fakültesi",
                        program_code: "Halkla İlişkiler",
                        national_number: 21111111111,
                        card_status: "Kart Oluşturulmadı",
                        issue_date: null
                    }
                ]
                this.datatable.total = 2
                this.datatable.isLoading = false
			},
			// Clear
			formClear() {

			},

            cardCreate(row) {
                if(row.card_status == this.$t('card_created')) {
                    this.$swal({
                        icon: "info",
                        title: this.$t('info'),
                        text: this.$t('created_card_info',{date: row.issue_date}),
                        confirmButtonText: this.$t('ok')
                    })
                } else {
                    this.$toast.success(this.$t('card_created'))
                }
            },

            cardPrint(row) {
                if(row.card_status == this.$t('card_created')) {
                    this.selectedData = row
                    this.$refs.printModal.$refs.commonModal.show()
                } else {
                    this.$swal({
                        icon: "info",
                        title: this.$t('info'),
                        text: this.$t('card_not_created'),
                        confirmButtonText: this.$t('ok')
                    })
                }

            },

            checkUrlForStudentNumber(){
                if(this.$route.params.studentNumber !== undefined) {
                    this.datatable.queryParams.filter.student_number = parseInt(this.$route.params.studentNumber)
                    this.getRows()
                }
            }

		}
	}
</script>


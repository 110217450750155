<template>
	<div>
        {{cardData}}
    </div>
</template>
<script>
	// Template

	// Components

    //Pages

	// Services

	// Others

	export default {
        props: {
            cardData: {
                type: Object,
                default: {}
            }
        },
		components: {

		},

		data() {
			return {

			}
		},
		created() {

		},
		methods: {

		}
	}
</script>

